import {
  AddressBaseInstance,
  AdminPanelItemInstance,
  APCustomerDetailGear,
  APCustomerOfferDetail,
  APCustomerOrder,
  CustomPageBaseData,
  DashboardSalesReports,
  DropdownOption,
  ExtendedAdminPanelItemInstance,
  GearFilterCategory,
  GmcItem,
  IconForPage,
  IconList,
  ListResponseData,
  QueryParams,
  AdminPanelMessagesAlertInstance,
  AdminPanelOrderInstance,
  APCustomerTransaction,
  AdminPanelCommentsAlertInstance,
  AdminPanelCommentsInstance,
} from 'types';
import { DIGIT_REGEX, STATUSES_REPLACEMENT_MAP } from 'consts';
import { GetServerSidePropsContext } from 'next/types';
import { formatErrorsForFormNotification, formatServerErrorsForNotification } from './services';
import { isNumber } from './validators';

export const safePush = (list: Array<any>, item: any): Array<any> => {
  if (list.includes(item)) return list;

  return [...list, item];
};

export const safeTextInsert = (text?: string | string[], prefix?: string): string =>
  text ? String(prefix ? safeTextInsert(prefix) + text : text) : prefix || '';

export const sortingByObjectField = {
  asc: (source: any[], field: string) => {
    return [...source].sort((a, b) => (a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0));
  },
  desc: (source: any[], field: string) => {
    return source.sort((a, b) => (a[field] < b[field] ? 1 : b[field] < a[field] ? -1 : 0));
  },
  none: (source: any[]) => {
    return source;
  },
};

export const composeServerErrorForSnackbar = (response: any) => {
  return formatServerErrorsForNotification(response);
};

export const composeAddAddressError = (response: any) => {
  const filteredErrors = (response.errors || []).filter(
    (error: string) =>
      error.startsWith('zip') ||
      error.startsWith('state') ||
      error.startsWith('location_zip') ||
      error.startsWith('location_state') ||
      error.startsWith('location_address') ||
      error.startsWith('address') ||
      error.startsWith('city'),
  );
  const sanitizedErrors = filteredErrors.map((error: string) => error.split(': ')?.[1]);
  console.log(response.errors)
  const showWarning =
    sanitizedErrors.length === 1 &&
    !!response.errors.find((error: string) => error.startsWith('location_address') || error.startsWith('address'));
  if (showWarning) {
    return {
      mode: 'error',
      header: 'That address can not be used by shipping service',
      details: sanitizedErrors,
      period: 5000 + sanitizedErrors.length * 2000,
      showWarning,
    };
  }

  return formatServerErrorsForNotification(response);
};

export const getFormattedDate = (timestamp: number, format: string, isUtc?: boolean): string => {
  const jsTimestamp = timestamp < 1000000000000 ? timestamp * 1000 : timestamp;
  const date = new Date(jsTimestamp);
  return formatDate(date, format, isUtc);
};

export const getFormattedTime = (timestamp: number, format: string, isUtc?: boolean): string => {
  const date = new Date(timestamp);
  return formatTime(date, format, isUtc);
};

export const getFormattedDateTime = (timestamp: number, format: string, isUtc?: boolean) =>
  getFormattedTime(timestamp, getFormattedDate(timestamp, format, isUtc), isUtc);

const fullMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const fullDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const formatDate = (date: Date, format: string, isUtc?: boolean): string => {
  if (!date || typeof date.getMonth !== 'function') return '';

  const month = isUtc ? date.getUTCMonth() : date.getMonth();
  const dd = String(isUtc ? date.getUTCDate() : date.getDate()).padStart(2, '0');
  const mm = String(month + 1).padStart(2, '0');
  const yyyy = String(isUtc ? date.getUTCFullYear() : date.getFullYear());
  const mon = (isUtc ? date.toISOString() : date.toString()).split(' ')[1];
  const mont = fullMonths[month];
  const day = fullDays[isUtc ? date.getUTCDay() : date.getDay()];

  let formatted = format;

  formatted = formatted.replace('YYYY', yyyy);
  formatted = formatted.replace('MM', mm);
  formatted = formatted.replace('DD', dd);
  formatted = formatted.replace('MONTH', mont);
  formatted = formatted.replace('MON', mon);
  formatted = formatted.replace('day', day);

  return formatted;
};

export const formatTime = (date: Date, format: string, isUtc?: boolean): string => {
  let hours: number | string = isUtc ? date.getUTCHours() : date.getHours();
  let ampm = '';
  let minutes: number | string = isUtc ? date.getUTCMinutes() : date.getMinutes();
  let seconds: number | string = isUtc ? date.getUTCSeconds() : date.getSeconds();
  let timezone: string | undefined = date.toString().match(/\(([A-Za-z\s].*)\)/)?.[1];

  if (format.indexOf('AA') > -1) {
    ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
  }

  hours = hours < 10 ? '0' + hours : hours;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  seconds = seconds < 10 ? '0' + seconds : seconds;

  let formatted = format;

  // @ts-ignore
  formatted = formatted.replace('HH', hours);
  // @ts-ignore
  formatted = formatted.replace('MM', minutes);
  // @ts-ignore
  formatted = formatted.replace('SS', seconds);
  formatted = formatted.replace('AA', ampm);
  formatted = formatted.replace('TZ', timezone?.match(/\b([A-Z])/g).join(''));

  return formatted;
};

export const formatDateAgo = (unixTimestamp: number) => {
  const intervals = [
    // { devider: 31536000, text: ' years ago' },
    { devider: 2592000, text: 'over a month ago' },
    { devider: 86400, text: ' days ago' },
    { devider: 3600, text: ' hours ago' },
    { devider: 60, text: ' minutes ago' },
  ];
  const seconds = Math.floor(Date.now() / 1000) - unixTimestamp;

  for (const key in intervals) {
    const interval = seconds / intervals[key].devider;
    if (interval > 1) {
      return key==='0' ? intervals[key].text : `${Math.floor(interval)} ${intervals[key].text}`;
    }
  }

  return 'Just Now';
};

export const formatDateIn = (unixTimestamp: number) => {
  const intervals = [
    { devider: 3600, text: ' hours' },
    { devider: 60, text: ' minutes' },
  ];
  const seconds =  unixTimestamp - Math.floor(Date.now() / 1000);

  for (const key in intervals) {
    const interval = seconds / intervals[key].devider;
    if (interval > 1) {
      return `${Math.floor(interval)} ${intervals[key].text}`;
    }
  }

  return 'Just Now';
};

export const formatStringDateIn = (date: string) => {
  const intervals = [
    { devider: 3600, text: ' hours' },
    { devider: 60, text: ' minutes' },
  ];
  const seconds = Math.floor((new Date(date)).getTime() / 1000) - Math.floor(Date.now() / 1000);

  for (const key in intervals) {
    const interval = seconds / intervals[key].devider;
    if (interval > 1) {
      return `${Math.floor(interval)} ${intervals[key].text}`;
    }
  }

  return 'Just Now';
};

export const getFormattedForServerDate = (date?: Date, time?: Date, useUtc?: boolean) =>
  `${formatDate(date || new Date(), 'YYYY-MM-DD', useUtc)} ${formatTime(time || new Date(), 'HH:MM:00', useUtc)}`;

export const convertServerBlockedToClient = (dates: string[]): Date[] => {
  return dates.map((date) => new Date(date));
};

const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

const weekDaysConversionMap = [7, 1, 2, 3, 4, 5, 6];

export const getThisWeekFirstLastDays = (keyDate = new Date(Date.now())) => {
  const currentDay = weekDaysConversionMap[keyDate.getDay()];
  const firstWeekDay = new Date(keyDate.valueOf() - oneDayInMilliseconds * (currentDay - 1));
  const lastWeekDay = new Date(keyDate.valueOf() + oneDayInMilliseconds * (7 - currentDay));

  return [firstWeekDay, lastWeekDay];
};

export const getLastWeekFirstLastDays = () => getThisWeekFirstLastDays(new Date(Date.now() - oneDayInMilliseconds * 7));

export const getThisMonthFirstLastDays = (keyDate = new Date(Date.now())) => {
  const year = keyDate.getFullYear();
  const month = keyDate.getMonth();
  const firstMonthDay = new Date(year, month, 1);
  const lastMonthDay = new Date(year, month + 1, 0);

  return [firstMonthDay, lastMonthDay];
};

export const getLastMonthFirstLastDays = () => {
  const keyDate = new Date(Date.now());
  const year = keyDate.getFullYear();
  const month = keyDate.getMonth() - 1;
  return getThisMonthFirstLastDays(new Date(year, month));
};

export const capitalizeWord = (word: string): string => `${word?.[0]?.toUpperCase()}${word?.substring(1)}`

export const capitalizeAllWords = (string: string) => {
  if (!string) return '';

  return string
    .split(' ')
    .map((word) => capitalizeWord(word))
    .join(' ');
};

export const capitalizeSentences = (string: string) => {
  if (!string) return '';

  return string
    .toLowerCase()
    .split('.')
    .map((word) => capitalizeWord(word))
    .join('.');
}

export const convertSlugToCaption = (slug: string, delimiter = '_'): string => {
  if (!slug) return '';
  if (STATUSES_REPLACEMENT_MAP[slug]) {
    return STATUSES_REPLACEMENT_MAP[slug];
  }
  return capitalizeAllWords(slug.split(delimiter).join(' '));
};

export const formatArrayForQuery = (name: string, array: Array<Exclude<any, Function>>): QueryParams => {
  return array.reduce((acc, item, index) => {
    acc[`${name}[${index}]`] = item;
    return acc;
  }, {});
};

export const formatNumberToLetteredValue = (num: number, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let item = lookup.reverse().find((item) => num >= item.value);

  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
};

export const convertNumberedObjectToArray = (source: Record<string | number, any>) => {
  if (Array.isArray(source)) {
    return source;
  }
  return Object.keys(source).reduce((acc: any[], key: string | number) => {
    if (!!String(key).match(DIGIT_REGEX)) {
      acc.push(source[key]);
      return acc;
    }
    return acc;
  }, [] as any[]);
};

export const composeAddresses = (address: AddressBaseInstance = {} as AddressBaseInstance) =>
  `${address.address}, ${address.city}, ${address.state} ${address.zip}`;

export const removeSecondsFromTime = (time: string) => {
  const splittedTime = time.split(':');
  if (splittedTime.length > 2) {
    return `${splittedTime[0]}:${splittedTime[1]}`;
  }
  return time;
};

export const extractFilenameFromUrl = (url: string) => {
  const splittedUrl = url.split('/');
  if (!splittedUrl.length || splittedUrl.length < 2) return url;

  return splittedUrl[splittedUrl.length - 1];
};

export const formatPrice = (price: number) => Math.round(price * 100) / 100;

export const stringifyQueryFilters = (filters: QueryParams) =>
  Object.entries(filters).map((filter) =>
    filter[1] ? `${filter[0]}=${typeof filter[1] === 'object' ? JSON.stringify(filter[1]) : filter[1]}` : '',
  );

export const formatFiltersToQueryString = (filters: QueryParams = {}) => {
  if (!Object.keys(filters).length) return '';
  const composedFilterKeyValues = stringifyQueryFilters(filters).filter((f) => !!f);
  return `?${composedFilterKeyValues.join('&')}`;
};

export const getPriceString = (
  price: string | number,
  isRent: boolean,
  hourlyPrice?: number,
  isHourlyRent?: boolean,
  rentDayLength: number = 0,
  currency: string = 'USD',
) => {
  if (!isRent) {
    return `$${price} ${currency}`;
  }
  if (hourlyPrice && isHourlyRent) {
    return `$${hourlyPrice} ${currency}/h`;
  }
  if (hourlyPrice && rentDayLength <= 1) {
    return `$${price} ${currency}/d, ${hourlyPrice} ${currency}/h`;
  }
  return `$${price} ${currency}/day`;
};

export const formatUnicodeTimeToImperial = (time: string): string => {
  const [hours, minutes] = time.split(':');
  if (hours && +hours > 12) {
    const pmHours = +hours - 12;
    return `${pmHours < 10 ? '0' + pmHours : pmHours}:${minutes} PM`;
  }
  return time + ' AM';
};

export const getMinHoursPostfix = (minHours?: number) => {
  if (!minHours) {
    return '';
  }
  return ` (min ${minHours} hour${minHours === 1 ? ')' : 's)'}`;
};

export const sanitizeCategories = (categories: GearFilterCategory[], prefix: string = '') => {
  return categories.reduce((acc: GearFilterCategory[], category) => {
    acc.push({ ...category, title: `${prefix}${category.title}` });
    if (category.children) {
      acc.push(...sanitizeCategories(category.children, prefix ? `\xa0\xa0\xa0${prefix}` : '\xa0\xa0\xa0'));
    }
    return acc;
  }, [] as GearFilterCategory[]);
};

export const pushEmptyValueToDropdownOptions = (options: DropdownOption[]): DropdownOption[] => [
  { title: '...', value: undefined },
  ...options,
];

export const setNestedFieldsIfNeeded = (fiendName: string, data: any) => {
  if (!fiendName.includes('.')) {
    return { [fiendName]: data };
  }
  const fieldNames = fiendName.split('.');
  return fieldNames.reverse().reduce((acc, name) => {
    return { [name]: acc };
  }, data as Record<string, any>);
};

export const mergeSingleProbablyNestedValueToObject = (
  origin: Record<string, any>,
  additional: Record<string, any>,
): Record<string, any> => {
  const entries = Object.entries(additional);
  if (!additional || !entries.length) return origin;
  const key = entries[0][0];
  const value = entries[0][1];
  if (!origin[key]) {
    return { ...origin, ...additional };
  }
  if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
    return { ...origin, [key]: mergeSingleProbablyNestedValueToObject(origin[key], value) };
  }
  return { ...origin, ...additional };
};

export const getCanonicalUrlFromContext = (context: GetServerSidePropsContext<any, any>) =>
  `${context.req.headers.host}${sanitizeNextUrl(`${context.req.url}`)}`;

export const sanitizeNextUrl = (url: string) => {
  if (url.startsWith('/_next')) {
    return `/${url.split('.json')[0].split('/').slice(4).join('/')}`;
  }
  return url;
};

export const sanitizeAdminPanelGear = (
  gear: ListResponseData<AdminPanelItemInstance[]>,
): ListResponseData<ExtendedAdminPanelItemInstance[]> => {
  return {
    ...gear,
    data:
      gear.data?.map((gear) => {
        return {
          ...gear,
          price: `${gear.price} USD${gear.type === 'rent' ? ' / day' : ''}${
            gear.hourly_rent_enabled && gear.hourly_price ? `, ${gear.hourly_price} USD / hr` : ''
          }`,
          links: [
            {
              targetField: 'title',
              icon: '/icons/ExternalLink.svg',
              link: `/gear/${gear.slug}`,
            },
            {
              targetField: 'username',
              icon: '/icons/ExternalLink.svg',
              link: `/users/${gear.username}`,
            },
            {
              targetField: 'username',
              icon: '/icons/people/UserSquareBlue.svg',
              link: `/admin-panel/customers/${gear.user_id}/details`,
            },
          ],
        };
      }) || [],
  };
};

export const sanitizeDashboardReports = (reports: DashboardSalesReports) => {
  return [...(reports.periods || []), { ...reports.total, period: 'Grand Total', isTotal: true }];
};

export const sanitizeAdminPanelUserDetailGears = (gears: ListResponseData<APCustomerDetailGear[]>) => {
  return {
    ...gears,
    data:
      gears.data?.map((gear) => {
        return {
          ...gear,
          links: [
            {
              targetField: 'title',
              icon: '/icons/ExternalLink.svg',
              link: `/gear/${gear.slug}`,
            },
          ],
        };
      }) || [],
  };
};

export const sanitizeAdminPanelUserDetailOrders = (orders: ListResponseData<APCustomerOrder[]>) => {
  return {
    ...orders,
    data:
      orders.data?.map((gear) => {
        return {
          ...gear,
          links: [
            {
              targetField: 'title',
              icon: '/icons/ExternalLink.svg',
              link: `/gear/${gear.slug}`,
            },
            {
              targetField: 'buyer',
              icon: '/icons/ExternalLink.svg',
              link: `/users/${gear.buyer.username}`,
            },
            {
              targetField: 'buyer',
              icon: '/icons/people/UserSquareBlue.svg',
              link: `/admin-panel/customers/${gear.buyer.id}/details`,
            },
          ],
        };
      }) || [],
  };
};

export const sanitizeAdminPanelUserDetailTransactions = (transactions: ListResponseData<APCustomerTransaction[]>) => {
  return {
    ...transactions,
    data:
      transactions.data?.map((transaction) => {
        const additionalData = {};

        if (transaction.order_id) {
          additionalData.links = [
            {
              targetField: 'order_id',
              icon: '/icons/ExternalLink.svg',
              link: `/admin-panel/orders/${transaction.order_id}`,
            },
          ];
        }

        return {
          ...transaction,
          ...additionalData,
        };
      }) || [],
  };
};

export const sanitizeGmcItemTableData = (gmcItems: ListResponseData<GmcItem[]>) => {
  return {
    ...gmcItems,
    data: gmcItems.data?.map((item) => {
      return {
        ...item,
        id: item.item_id,
      };
    }),
  };
};

export const getResendCodeErrorNotification = (response: any, secondsLeft: number | string) => {
  if (typeof secondsLeft !== 'number' || secondsLeft < 1) {
    return formatServerErrorsForNotification(response)
  }
  if (secondsLeft < 60) {
    return {
      header: response.message || '',
      message: `You can request new code in ${secondsLeft} seconds`,
      mode: 'error',
    };
  }
  const seconds = secondsLeft % 60;
  const rawMinutes = secondsLeft / 60;
  const minutes = Math.floor(rawMinutes % 60);
  const hours = Math.floor(rawMinutes / 60);
  const literalTime = `${hours ? String(hours).padStart(2, '0') + ':' : ''}${String(minutes).padStart(2, '0')}:${String(
    seconds,
  ).padStart(2, '0')}`;
  const messageString = `You can request new code in ${literalTime}`;

  return {
    header: response.message || '',
    message: messageString,
    mode: 'error',
  };
};

export const removeArrayIndexFromStringIfNeeded = (field: string) => {
  const indexStart = field.indexOf('[');
  if (indexStart < 0) return field;
  return field.slice(0, field.indexOf('['));
};

export const formatQueryFilters = (query: Record<string, string | string[]>) => {
  const appliedFilters = { ...query };
  if (query.markets) appliedFilters.markets = JSON.parse(query.markets as string);
  if (query.activities) appliedFilters.activities = JSON.parse(query.activities as string);
  if (query.brands) appliedFilters.brands = JSON.parse(query.brands as string);
  if (query.colors) appliedFilters.colors = JSON.parse(query.colors as string);
  if (query.sizes) appliedFilters.sizes = JSON.parse(query.sizes as string);
  if (query.conditions) appliedFilters.conditions = JSON.parse(query.conditions as string);
  if (query.page) appliedFilters.page = JSON.parse(query.page as string);
  if (query.type) appliedFilters.type = (typeof query.type === 'string') ? query.type.replace('"', '').replace('"', '') : '';

  return appliedFilters;
};

export const removeHtmlTagFromString = (line: string) => line.replace(/<(.|\n)*?>/g, '').trim();

export const sanitizeDigitsString = (string: string) =>
  string
    .split('')
    .filter((symbol: string) => isNumber(symbol))
    .join('');

export const sanitizeAdminPanelOffers = (offers: ListResponseData<APCustomerOfferDetail[]>) => {
  return {
    ...offers,
    data:
      offers.data?.map((offer) => {
        return {
          ...offer,
          links: [
            {
              targetField: 'buyer',
              icon: '/icons/ExternalLink.svg',
              link: `/users/${offer.buyer.username}`,
            },
            {
              targetField: 'buyer',
              icon: '/icons/people/UserSquareBlue.svg',
              link: `/admin-panel/customers/${offer.buyer.id}/details`,
            },
            {
              targetField: 'seller',
              icon: '/icons/ExternalLink.svg',
              link: `/users/${offer.seller.username}`,
            },
            {
              targetField: 'seller',
              icon: '/icons/people/UserSquareBlue.svg',
              link: `/admin-panel/customers/${offer.seller.id}/details`,
            },
            {
              targetField: 'item',
              icon: '/icons/ExternalLink.svg',
              link: `/gear/${offer.item.slug}`,
            },
            {
              targetField: 'order_id',
              icon: '/icons/ReceiptBlue.svg',
              link: `/admin-panel/orders/${offer.order_id}`,
            },
          ],
        };
      }) || [],
  };
};

export const sanitizeAdminPanelOrders = (orders: ListResponseData<AdminPanelOrderInstance[]>) => {
  return {
    ...orders,
    data:
      orders.data?.map((order) => {
        const links = [
          {
            targetField: 'seller',
            icon: '/icons/ExternalLink.svg',
            link: `/users/${order.seller.username}`,
          },
          {
            targetField: 'seller',
            icon: '/icons/people/UserSquareBlue.svg',
            link: `/admin-panel/customers/${order.seller.id}/details`,
          },
          {
            targetField: 'item',
            icon: '/icons/ExternalLink.svg',
            link: `/gear/${order.item.slug}`,
          },
        ];

        if (!order.buyer.is_guest) {
          links.push({
            targetField: 'buyer',
            icon: '/icons/ExternalLink.svg',
            link: `/users/${order.buyer.username}`,
          });
          links.push({
            targetField: 'buyer',
            icon: '/icons/people/UserSquareBlue.svg',
            link: `/admin-panel/customers/${order.buyer.id}/details`,
          });
        }

        return {
          ...order,
          links,
        };
      }) || [],
  };
};

export const sanitizeAdminPanelCustomPages = (pages: ListResponseData<CustomPageBaseData[]>) => {
  return {
    ...pages,
    data: pages.data?.map((page: CustomPageBaseData) => ({
      ...page,
      published: page.published ? 'Published' : 'Draft',
    })),
  };
};

export const sanitizeIcons = (icons: IconList = {}): IconForPage[] =>
  Object.entries(icons).reduce((acc, instance): IconForPage[] => {
    const iconSet: IconForPage[] = instance[1] as IconForPage[];
    if (Array.isArray(iconSet)) {
      return [...acc, ...iconSet.map((icon) => ({ ...icon, title: icon.name || '' }))];
    }
    return acc;
  }, [] as IconForPage[]);

export const sanitizeAdminPanelMessagesAlert = (
  alert: ListResponseData<AdminPanelMessagesAlertInstance[]>,
): ListResponseData<AdminPanelMessagesAlertInstance[]> => {
  return {
    ...alert,
    data:
      alert.data?.map((alert) => {
        return {
          ...alert,
          links: [
            {
              targetField: 'sender',
              icon: '/icons/ExternalLink.svg',
              link: `/users/${alert.sender.username}`,
            },
            {
              targetField: 'sender',
              icon: '/icons/people/UserSquareBlue.svg',
              link: `/admin-panel/customers/${alert.sender.id}/details`,
            },
            {
              targetField: 'receiver',
              icon: '/icons/ExternalLink.svg',
              link: `/users/${alert.receiver.username}`,
            },
            {
              targetField: 'receiver',
              icon: '/icons/people/UserSquareBlue.svg',
              link: `/admin-panel/customers/${alert.receiver.id}/details`,
            },
          ],
        };
      }) || [],
  };
};

export const sanitizeAdminPanelCommentsAlert = (
  alert: ListResponseData<AdminPanelCommentsAlertInstance[]>,
): ListResponseData<AdminPanelCommentsAlertInstance[]> => {
  return {
    ...alert,
    data:
      alert.data?.map((alert) => {
        return {
          ...alert,
          links: [
            {
              targetField: 'sender',
              icon: '/icons/ExternalLink.svg',
              link: `/users/${alert.sender.username}`,
            },
            {
              targetField: 'sender',
              icon: '/icons/people/UserSquareBlue.svg',
              link: `/admin-panel/customers/${alert.sender.id}/details`,
            },
            {
              targetField: 'receiver',
              icon: '/icons/ExternalLink.svg',
              link: `/users/${alert.receiver.username}`,
            },
            {
              targetField: 'receiver',
              icon: '/icons/people/UserSquareBlue.svg',
              link: `/admin-panel/customers/${alert.receiver.id}/details`,
            },
            {
              targetField: 'item',
              icon: '/icons/ExternalLink.svg',
              link: `/gear/${alert.item.slug}`,
            },
          ],
        };
      }) || [],
  };
};

export const sanitizeAdminPanelComments = (
  alert: ListResponseData<AdminPanelCommentsInstance[]>,
): ListResponseData<AdminPanelCommentsInstance[]> => {
  return {
    ...alert,
    data:
      alert.data?.map((alert) => {
        return {
          ...alert,
          links: [
            {
              targetField: 'sender',
              icon: '/icons/ExternalLink.svg',
              link: `/users/${alert.sender.username}`,
            },
            {
              targetField: 'sender',
              icon: '/icons/people/UserSquareBlue.svg',
              link: `/admin-panel/customers/${alert.sender.id}/details`,
            },
            {
              targetField: 'item',
              icon: '/icons/ExternalLink.svg',
              link: `/gear/${alert.item.slug}`,
            },
          ],
        };
      }) || [],
  };
};


export const canContinueOrder = (errorMessage: string) => {
  return !(
    errorMessage === 'Payment confirmation taking too long. If the funds have been debited from the balance and the order will not be confirmed, contact the support please'
    || errorMessage === 'Gear is not available already'
    || errorMessage === 'Order creation was canceled or form has expired. Please, create your order again'
  );
}
